import { Client } from 'typesense';

// Create a new client
let client = {};

client = new Client({
    nodes: [{
        host: 'rip02c3nojftgkd1p-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
    }],
    apiKey: '4ThlzkaMbc4bR7Mh8pLt7qtCi01DOCbi',
    connectionTimeoutSeconds: '2',
});

export default client;

//Dev
// const typsenseHost = 'https://rip02c3nojftgkd1p-1.a1.typesense.net'
// const typsenseAPIKEY = '4ThlzkaMbc4bR7Mh8pLt7qtCi01DOCbi'

//Production
// const typsenseHost = 'https://cmbit0xgzdhl9k4yp-1.a1.typesense.net'
// const typsenseAPIKEY = 'LFAQOlZ8nfegH8WL0OBUYs0098BSfXb5'